import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../context/MainContext";
import "./navmenu.scss";
import { FULL_LOGO, LOGO_ICON } from "../../config";
import s1 from "../../static/images/s1.svg";
import s2 from "../../static/images/s2.svg";
import s3 from "../../static/images/s3.svg";
import s4 from "../../static/images/s4.svg";
import s5 from "../../static/images/s5.svg";
import s6 from "../../static/images/s6.svg";
import s7 from "../../static/images/s7.svg";
// import s1 from "../../static/images/s1.svg";
import c1 from "../../static/images/c1.svg";
import c2 from "../../static/images/c2.svg";
import c3 from "../../static/images/c3.svg";
import c4 from "../../static/images/c4.svg";

import p1 from "../../static/images/p1.svg";
import p2 from "../../static/images/p2.svg";
import p3 from "../../static/images/p3.svg";
import p4 from "../../static/images/p4.svg";
import p5 from "../../static/images/p5.svg";
import p6 from "../../static/images/p6.svg";
import p7 from "../../static/images/p7.svg";

import p8 from "../../static/images/p8.svg";

import a1 from "../../static/images/a1.svg";
import a2 from "../../static/images/a2.svg";
import a3 from "../../static/images/a3.svg";
import a4 from "../../static/images/a4.svg";

import o1 from "../../static/images/o1.svg";
import o2 from "../../static/images/o2.svg";
import o3 from "../../static/images/o3.svg";
import o4 from "../../static/images/o4.svg";
import logo from "../../static/images/dglogo.svg";
import box from "../../static/images/bx.svg";

import z5 from "../../static/images/z5.svg";
import z6 from "../../static/images/z6.svg";
import retaire from "../../static/images/retaire.svg";
import DropdownData from "../DropDown/dropdown";

import down from "../../static/images/dropdown/down.svg";
import up from "../../static/images/dropdown/up.svg";

// import metaverse from '../../static/images/about/metaverse.svg';

// import sharetokens from '../../static/images/about/sharetokens.svg';
// import safetokens from '../../static/images/about/safetokens.svg';
// import csoptokens from '../../static/images/about/csoptokens.svg';
// import esoptokens from '../../static/images/about/esoptokens.svg';
// import bondtokens from '../../static/images/about/bondtokens.svg';
// import estatetokens from '../../static/images/about/estatetokens.svg';

// import indianinvestor from '../../static/images/about/indianinvestor.svg';
// import byindustry from '../../static/images/about/byindustry.svg';
// import successstories from '../../static/images/about/successstories.svg';

const productMenu = [
  {
    id: 0,
    title: "MarketsOS",
    desc: "Suite of infrastructure products to build your fintech app.",
    icon: "marketos",
  },
  {
    id: 1,
    title: "MyCryptoBrand",
    desc: "Plug and play loyalty infused commerce solution for businesses.",
    icon: "mymcb",
  },
  {
    id: 2,
    title: "Metaverse App Store",
    desc: "List your business on the worlds first Metaverse App Store.",
    icon: "metaverse",
  },
];

const protocolMenu = [
  {
    id: 0,
    title: "ShareTokens",
    desc: "Representing a direct equity interest in a private company.",
    icon: "sharetokens",
  },
  {
    id: 1,
    title: "SafeTokens",
    desc: "Representing a CCPS agreement in a private company.",
    icon: "safetokens",
  },
  {
    id: 2,
    title: "CSOPTokens",
    desc: "Representing a CSOP agreement in a private company.",
    icon: "csoptokens",
  },
  {
    id: 3,
    title: "ESOPTokens",
    desc: "Representing a ESOP agreement in a private company.",
    icon: "esoptokens",
  },
  {
    id: 4,
    title: "BondTokens",
    desc: "Representing various corporate debt instruments.",
    icon: "bondtokens",
  },
  {
    id: 5,
    title: "EstateTokens",
    desc: "Representing ownership in a real estate asset.",
    icon: "estatetokens",
  },
];

const companyMenu = [
  {
    id: 0,
    title: "About Us",
    desc: "Learn about our mission, history and vision.",
  },
  {
    id: 1,
    title: "Team",
    desc: "Meet the founders and operational team that built the MarketsVerse.",
  },
  {
    id: 2,
    title: "Press",
    desc: "See everywhere MarketsVerse is mentioned in the media.",
  },
  {
    id: 3,
    title: "Contact",
    desc: "We would love to speak to you.",
  },
];

// const [selectedcontactquery,setselectedcontactquery]=useState("")
const Navmenu = () => {
  const [navHover, setnavHover] = useState("");
  const [companynav, setcompanynav] = useState("");
  const [selectNav, setselectNav] = useState("About");
  let navigate = useHistory();
  const { navHovercontext, setnavHovercontext, setname, setimageName } =
    useContext(MainContext);

  const [openactions, setOpenactions] = useState(false);
  const [userwidth, setuserwidth] = useState(0);
  const [airDrop, setairDrop] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 60) {
      setOpenactions(true);
    } else {
      setOpenactions(false);
    }
  };
  const handleGo = (name) => {
    console.log(name, "hiiii");
    if (name === "About Us") {
      setcompanynav("company");
      navigate.push("/thecompany");
    } else if (name === "Team") {
      setcompanynav("founder");
      navigate.push("/thecompany/founder");
    } else if (name === "Contact") {
      setcompanynav("contactus");
      //  setselectedcontactquery("");
      navigate.push("/thecompany/contactus");
    } else if (name === "MarketsOS") {
      window.open("https://marketsverse.com/products/digital%20bank", "_blank");
    } else if (name === "MyCryptoBrand") {
      window.open("https://mycryptobrand.com", "_blank");
    } else if (name === "Metaverse App Store") {
      window.open("https://metaverseapp.store", "_blank");
    }
  };
  console.log("openactions", openactions);
  return (
    <>
      {airDrop && <DropdownData setairDrop={setairDrop} />}

      <div
        className="menushow"

        // style={
        //   openactions
        //     ? { boxShadow: "0 5px 7px -10px #000000" }
        //     : { opacity: "1" }
        // }
      >
        <div
          className="nn-main  "
          style={{
            boxShadow: !navHover == "" && "0px 0px 0px rgba(0, 0, 0, 0.0)",
            borderRadius:
              airDrop || !navHover == "" ? "15px 15px 0px 0px " : "15px",
          }}
        >
          <div className="container nn-maincustom">
            <div className="nn-left" onMouseLeave={() => setnavHover("")}>
              {/* <img
                className="nn-img1"
                onMouseEnter={() => setnavHover("box")}
                //
                src={box}
                alt=""
              /> */}
              <img
                className="nn-img"
                src={logo}
                alt=""
                onClick={() => {
                  navigate.push("/");
                }}
              />
              <div className="airborderInfo"></div>
              <div className="air" onClick={() => setairDrop(!airDrop)}>
                BY AIR
                {airDrop ? <img src={up} /> : <img src={down} />}
              </div>
              <div
                className="big-box3 thebox"
                style={{
                  display: navHover === "box" ? "" : "none",
                  opacity: !navHover == "" && 1,
                }}
                onMouseEnter={() => setnavHover("box")}
                onMouseLeave={() => setnavHover("")}
              >
                <div className="menus-left">
                  <div className="menu-title">Our Parent Organizations</div>
                  <section
                    className="menus"
                    onClick={() => window.open("https://dce.group/", "_blank")}
                  >
                    <div className="menu-img-box">
                      <img className="menu-img" src={z5} alt="" />
                    </div>
                    <div>
                      <div className="menu-title">
                        DCE | Dubai Crypto Exchange
                      </div>
                      <div className="menu-desc">
                        UAE Based Exchange Enabling Users To Trade Asset Backed
                        Tokens
                      </div>
                    </div>
                  </section>
                  <section
                    className="menus"
                    onClick={() => window.open("https://dgg.ai/", "_blank")}
                  >
                    <div className="menu-img-box">
                      <img className="menu-img" src={z6} alt="" />
                    </div>
                    <div>
                      <div className="menu-title">
                        DGG | Digital Global Group
                      </div>
                      <div className="menu-desc">
                        Global Financial Education Network & Developer Of
                        AIProWallet.
                      </div>
                    </div>
                  </section>
                  <section
                    className="menus"
                    onClick={() =>
                      window.open("https://retired.app/", "_blank")
                    }
                  >
                    <div className="menu-img-box">
                      <img className="menu-img" src={retaire} alt="" />
                    </div>
                    <div>
                      <div className="menu-title">Retired App</div>
                      <div className="menu-desc">
                        The worlds first AI powered Retirement planner.
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div className="nn-right" onMouseEnter={() => setairDrop(false)}>
              {/* <div className='nn-menu' onClick={(e) => handleNav('about')} style={{fontWeight: selectNav === 'about' ? '700' : ''}}>About</div> */}

              <div
                className="nn-menu"
                // onClick={(e) => selectNav === 'products' ? handleNav(prevnav) : handleNav('products')}
                // style={{
                //   fontWeight: selectNav === "About" ? "700" : "",
                //   opacity: selectNav === "About" ? "1" : "0.5",
                // }}
                onMouseEnter={() => setnavHovercontext("About")}
                onMouseLeave={() => setnavHover("")}
                onClick={() => {
                  navigate.push("/about");
                  setnavHovercontext("About");
                }}
              >
                About
                {navHovercontext === "About" && (
                  <div className="boderlenght"></div>
                )}
              </div>
              <div className="menu-flex">
                <div
                  className="nn-menu"
                  // onClick={(e) => selectNav === 'products' ? handleNav(prevnav) : handleNav('products')}
                  // style={{ fontWeight: selectNav === "products" ? "700" : "" }}
                  onMouseEnter={() => {
                    setnavHover("products");
                    setnavHovercontext("products");
                  }}
                  // onMouseLeave={() => setnavHover("")}
                  onMouseLeave={() => setnavHover("")}
                  onClick={() => {
                    // navigate.push("/products/aiindex");
                    setnavHovercontext("products");
                  }}
                >
                  Products
                  {navHovercontext === "products" && (
                    <div className="boderlenght"></div>
                  )}
                </div>
                <div
                  className="big-box3 theproduct"
                  onMouseEnter={() => setnavHover("products")}
                  onMouseLeave={() => setnavHover("")}
                  style={{
                    display: navHover === "products" ? "" : "none",
                    opacity: !navHover == "" && 1,
                    background: "white",
                  }}
                >
                  <div className="menus-left">
                    <div className="menu-title">Data Products</div>
                    <section
                      className="menus"
                      onClick={() => {
                        setimageName(p1);
                        setname("AI Index");
                        navigate.push("/products/aiindex");
                      }}
                      //   onClick={() =>
                      //     window.open("https://indianinvestor.com", "_blank")
                      //   }
                    >
                      <div className="menu-img-box">
                        <img className="menu-img" src={p1} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">AI Index</div>
                        <div className="menu-desc">
                          Compare automated trading products across companies.
                        </div>
                      </div>
                    </section>
                    <section
                      className="menus"
                      onClick={() => {
                        setimageName(p2);
                        setname("DGAcademy");
                        setnavHovercontext("products");
                        navigate.push("/products/DGAcademy");
                      }}
                      //   onClick={() =>
                      //     window.open("https://indianinvestor.com", "_blank")
                      //   }
                    >
                      <div className="menu-img-box">
                        <img className="menu-img" src={p2} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">DGAcademy</div>
                        <div className="menu-desc">
                          Learn how to incorporate AI in your investing
                          strategies.
                        </div>
                      </div>
                    </section>

                    <div className="menu-title" style={{ marginTop: "2rem" }}>
                      Exchange Products
                    </div>
                    <section
                      className="menus"
                      onClick={() => {
                        setimageName(p3);
                        setnavHovercontext("products");
                        setname("Fiat To Crypto");
                        navigate.push("/products/FiatToCrypto");
                      }}
                      //   onClick={() =>
                      //     window.open("https://indianinvestor.com", "_blank")
                      //   }
                    >
                      <div className="menu-img-box">
                        <img className="menu-img" src={p3} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Fiat To Crypto</div>
                        <div className="menu-desc">
                          Buy and sell crypto with CAD. More currencies to come.
                        </div>
                      </div>
                    </section>
                    <section
                      className="menus"
                      onClick={() => {
                        setimageName(p4);
                        setname("Crypto To Crypto");
                        setnavHovercontext("products");
                        navigate.push("/products/CryptoToCrypto");
                      }}
                      //   onClick={() =>
                      //     window.open("https://indianinvestor.com", "_blank")
                      //   }
                    >
                      <div className="menu-img-box">
                        <img className="menu-img" src={p4} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Crypto To Crypto</div>
                        <div className="menu-desc">
                          Seamlessly swap between 60+ cryptocurrencies.
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="menus-right">
                    <div className="menu-title">Automated Products</div>
                    <section
                      className="menus"
                      onClick={() => {
                        setimageName(p5);
                        setnavHovercontext("products");
                        setname("StakingVaults");
                        navigate.push("/products/StakingVaults");
                      }}
                    >
                      <div className="menu-img-box">
                        <img className="menu-img" src={p5} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">StakingVaults</div>
                        <div className="menu-desc">
                          Earn daily rewards for holding BTC, ETH, and USDT in
                          your wallet.
                        </div>
                      </div>
                    </section>
                    <section
                      className="menus"
                      onClick={() => {
                        setimageName(p6);
                        setnavHovercontext("products");
                        setname("OTCBots");
                        navigate.push("/products/OTCBots");
                      }}
                    >
                      <div className="menu-img-box">
                        <img className="menu-img" src={p6} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">OTCBots</div>
                        <div className="menu-desc">
                          Setup a recurring purchase of your favourite coin.
                        </div>
                      </div>
                    </section>
                    <section
                      className="menus"
                      onClick={() => {
                        setimageName(p7);
                        setname("DGFX");
                        setnavHovercontext("products");
                        navigate.push("/products/DGFX");
                      }}
                    >
                      <div className="menu-img-box">
                        <img className="menu-img" src={p7} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">DGFX</div>
                        <div className="menu-desc">
                          Our proprietary automated trading strategy for forex
                          markets.
                        </div>
                      </div>
                    </section>
                    <section
                      className="menus"
                      onClick={() => {
                        setimageName(p8);
                        setnavHovercontext("products");
                        setname("DGPROF");
                        navigate.push("/products/DGPROF");
                      }}
                    >
                      <div className="menu-img-box">
                        <img className="menu-img" src={p8} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">DGPROF</div>
                        <div className="menu-desc">
                          Our proprietary automated trading strategy for crypto
                          markets.
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              {/* <div
            className="nn-menu"
            onMouseEnter={() => setnavHover("protocols")}
            onMouseLeave={() => setnavHover("")}
          >
            Protocols
          </div> */}

              <div className="menu-flex">
                <div
                  className="nn-menu"
                  // onClick={(e) =>  handleNav('templates')}
                  style={{
                    fontWeight: selectNav === "Affiliates" ? "700" : "",
                  }}
                  //  onMouseLeave={() => setnavHover("")}
                  onMouseEnter={() => {
                    setnavHovercontext("Affiliates");
                    setnavHover("Affiliates");
                  }}
                  onMouseLeave={() => setnavHover("")}
                >
                  Affiliates
                  {navHovercontext === "Affiliates" && (
                    <div className="boderlenght"></div>
                  )}
                </div>
                <div
                  className="big-box3 theAffliate"
                  onMouseEnter={() => setnavHover("Affiliates")}
                  onMouseLeave={() => setnavHover("")}
                  style={{ display: navHover === "Affiliates" ? "" : "none" }}
                >
                  <div className="menus-left">
                    <div className="menu-title">Tools</div>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={a1} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Affiliate App</div>
                        <div className="menu-desc">
                          Discover & compare automated trading products across
                          companies.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={a2} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">NetworkChain</div>
                        <div className="menu-desc">
                          Learn how to incorporate AI in your investing
                          strategies.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={a3} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">AffiliateBank</div>
                        <div className="menu-desc">
                          Buy and sell crypto with CAD. More currencies to come.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={a4} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">InstantFunnels</div>
                        <div className="menu-desc">
                          Seamlessly swap between 60+ cryptocurrencies.
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="menus-right">
                    <div className="menu-title">Opportunities</div>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={o1} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">AI Index Referrals</div>
                        <div className="menu-desc">
                          Earn a percentage when your customers purchase a
                          subscription.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={o2} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">
                          Management Fee Override
                        </div>
                        <div className="menu-desc">
                          Earn part of the fee deducted from your customers
                          profits.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={o3} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Exchange Fees</div>
                        <div className="menu-desc">
                          Earn half the exchange fees paid by your entire
                          network.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={o4} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Staking Rewards Pool</div>
                        <div className="menu-desc">
                          Earn every time your customer gets a daily staking
                          reward.
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/* czx */}
              <div className="menu-flex">
                <div
                  className="nn-menu"
                  // onClick={(e) => handleNav('casestudies')}
                  style={{
                    fontWeight: selectNav === "Communities" ? "700" : "",
                  }}
                  onMouseEnter={() => {
                    setnavHovercontext("Communities");
                    setnavHover("Communities");
                  }}
                  onMouseLeave={() => setnavHover("")}
                >
                  Communities
                  {navHovercontext === "Communities" && (
                    <div className="boderlenght"></div>
                  )}
                </div>

                <div
                  className="big-box3 Thecommunicty"
                  onMouseEnter={() => setnavHover("Communities")}
                  onMouseLeave={() => setnavHover("")}
                  style={{ display: navHover === "Communities" ? "" : "none" }}
                >
                  <div className="menus-left">
                    <div className="menu-title">Tools</div>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={c1} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Branding Materials</div>
                        <div className="menu-desc">
                          Access all the presentations and sales documents you
                          need.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={c2} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Weekly Webinars</div>
                        <div className="menu-desc">
                          Register for our weekly opportunity and training
                          calls.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={c3} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Rankings & Rewards</div>
                        <div className="menu-desc">
                          See how we reward the movers and shakers in
                          AiProWallet.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div className="menu-img-box">
                        <img className="menu-img" src={c4} alt="" />
                      </div>
                      <div>
                        <div className="menu-title">Training Center</div>
                        <div className="menu-desc">
                          Browse through all the training videos and FAQ’s.
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="menus-right">
                    <div className="menu-title">Social</div>

                    <div className="socialmedia">
                      <div className="box">
                        <img src={s1} />
                      </div>
                      <div className="box">
                        <img src={s2} />
                      </div>
                      <div className="box">
                        <img src={s3} />
                      </div>
                      <div className="box">
                        <img src={s4} />
                      </div>
                    </div>

                    <div className="socialmedia">
                      <div className="box">
                        <img src={s5} />
                      </div>

                      <div className="box">
                        <img src={s6} />
                      </div>
                      <div className="box">
                        <img src={s7} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="menu-flex">
                <div
                  className="nn-menu"
                  //   onClick={(e) =>  handleNav('thecompany')}

                  style={{
                    fontWeight: selectNav === "thecompany" ? "700" : "",
                  }}
                  onMouseEnter={() => {
                    setnavHovercontext("thecomapny");
                    setnavHover("thecomapny");
                  }}
                  onMouseLeave={() => setnavHover("")}
                  // onMouseLeave={() => setnavHover("")}
                >
                  Company
                  {navHovercontext === "thecomapny" && (
                    <div className="boderlenght"></div>
                  )}
                </div>
                <div
                  className="big-box3 boxcompany"
                  onMouseEnter={() => setnavHover("thecomapny")}
                  onMouseLeave={() => setnavHover("")}
                  style={{ display: navHover === "thecomapny" ? "" : "none" }}
                >
                  <div className="menus-left">
                    <div className="menu-title">About Us</div>
                    <section className="menus">
                      <div>
                        <div className="menu-title">Mission</div>
                        <div className="menu-desc">
                          Learn about our mission, history and vision.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div>
                        <div className="menu-title">Press</div>
                        <div className="menu-desc">
                          See everywhere MarketsVerse is mentioned in the media.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div>
                        <div className="menu-title">Contact</div>
                        <div className="menu-desc">
                          We would love to speak to you.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div>
                        <div className="menu-title">Policies</div>
                        <div className="menu-desc">
                          Review all our legal and operating agreements.
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="menus-right">
                    <div className="menu-title">Team</div>
                    <section className="menus">
                      <div>
                        <div className="menu-title">Founders</div>
                        <div className="menu-desc">
                          Meet the founding team that built the AiProWallet.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div>
                        <div className="menu-title">Super Affiliates</div>
                        <div className="menu-desc">
                          Learn from an elite group of Super Affiliates who have
                          scaled their businesses.
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div>
                        <div className="menu-title">Influencers</div>
                        <div className="menu-desc">
                          Checkout the hottest online influencers promoting
                          AiProWallet
                        </div>
                      </div>
                    </section>
                    <section className="menus">
                      <div>
                        <div className="menu-title">Partners</div>
                        <div className="menu-desc">
                          Discover our amazing exchange partners in each
                          country.
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div
                className="nn-btn-login"
                onClick={() =>
                  window.open("https://my.aiprowallet.com/", "_blank")
                }
              >
                Login
              </div>
              <div
                className="nn-btn"
                onClick={() =>
                  window.open("https://my.aiprowallet.com/", "_blank")
                }
              >
                Get Started
              </div>
            </div>

            {/* <div className="end-nav">
          
          </div> */}
          </div>
        </div>

        <div className="nav-menu-container">
          {/* <div
          className="big-box1"
          onMouseEnter={() => setnavHover("products")}
          onMouseLeave={() => setnavHover("")}
          style={{ display: navHover === "products" ? "" : "none" }}
        >
          <div className="menu-title">Our Product Lines:</div>
          <div>
            {productMenu.map((each, i) => (
              <div
                className="each-menu"
                key={i}
                onClick={() => handleGo(each.title)}
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={each.icon} alt="" />
                </div>
                <div>
                  <div className="menu-title">{each.title}</div>
                  <div className="menu-desc">{each.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
          {/* <div
          className="big-box2"
          onMouseEnter={() => setnavHover("protocols")}
          onMouseLeave={() => setnavHover("")}
          style={{ display: navHover === "protocols" ? "" : "none" }}
        >
          <div className="menu-title">Our Protocols</div>
          <div>
            {protocolMenu.map((each, i) => (
              <div className="each-menu" key={i}>
                <div className="menu-img-box">
                  <img className="menu-img" src={each.icon} alt="" />
                </div>
                <div>
                  <div className="menu-title">{each.title}</div>
                  <div className="menu-desc">{each.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div> */}

          {/* c */}

          {/*  */}
        </div>
      </div>
    </>
  );
};

export default Navmenu;
