import React,{useContext} from "react";
import "./product.scss";
import index from "../static/images/index.svg";
import Menu from "../components/navMenus/navmenu";
import MobileNav from "../components/MobileNav";
import roundbox from "../static/images/roundbox.svg";
import { MainContext } from "../context/MainContext";
export default function Product() {
  const { navHovercontext, setnavHovercontext, name, imageName } =
  useContext(MainContext);
  return (
    <>
      <MobileNav />
      <Menu />

      <div className="mainproduct">
        <div className="container innerMainproduct">
          <div className="side-product">
            <div className="imageProduct">
              <img src={imageName} />
              <h2>{name}</h2>
            </div>
            <p>
              White Label MetaTrader 5 is a complete brokerage infrastructure
              used by multiple brokerages across the globe. Our white label
              solutions enable brokerages to launch quickly, incorporating a
              variety of options.
            </p>
          </div>
          <div className="leftsideproduct">
            <h1>Start Using Ai Index</h1>
            <div className="cutomh1">Start Using Ai Index For Free Today</div>
            <input placeholder="Enter Your Email..." />
            <div className="labelSectionproduct">
            <label>Get Started</label>
            <label
            style={{
              marginLeft:"10px"
            }}
            className="mobilelabel"
            >See Demo</label>
            </div>
        
          </div>
        </div>
        <div className="cardproduct">
          <div className="container Innercardproduct">
            <div className="cardProduct">
              <div className="owner">For App Owner</div>
              <div className="dis-title">
                Create, manage, and grow your asset manage business with the IFO
                system. Create, manage, and
              </div>
              <img src={roundbox} />
            </div>

            <div className="cardProduct">
              <div className="owner">For Your User</div>
              <div className="dis-title">
                Create, manage, and grow your asset manage business with the IFO
                system. Create, manage, and
              </div>
              <img src={roundbox} />
            </div>

            <div className="cardProduct">
              <div className="owner">For Your Issuer</div>
              <div className="dis-title">
                Create, manage, and grow your asset manage business with the IFO
                system. Create, manage, and
              </div>
              <img src={roundbox} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
