import React from "react";

import imgApi from "../../static/images/imgApi.svg";
import apiarrow from "../../static/images/apiarrow.svg";
import ailogo from "../../static/images/ailogo.svg";
import retlogo from "../../static/images/retlogo.svg";
import retArrow from "../../static/images/retArrow.svg";
import arrowwhite from "../../static/images/arrowwhite.svg";
import decdownlogo from "../../static/images/decdownlogo.svg";
import retdownlogo from "../../static/images/retdownlogo.svg";
import declogo from "../../static/images/declogo.svg";
import "./Ten.scss";
export default function Ten() {
  return (
    <div className="tenSection">
      <div className="container innerTenSection">
        <h1>The Ai Ecosystem</h1>
        <p>Leverage AI Across All Aspects Of Your Investing Journey</p>
        <div className="tencard">
          <div
            className="innertencard"
            style={{
              background: "#81cbd708",
            }}
          >
            <div className="toptencard">
              <img className="topImage" src={ailogo} />
            </div>
            <h2>Marketplace For AI Investing</h2>
            <h5>
              Discover and engage with AI enabled investment offerings from
              across the industry and automate your portfolio management.{" "}
            </h5>

            <div className="imageSectionTen">
              <div
                className="cirlcebutton"
                style={{
                  background:
                    "linear-gradient(155.61deg, #81CBD7 15.6%, #3D4394 135.63%)",
                }}
                onClick={() =>
                  window.open("https://my.aiprowallet.com/", "_blank")
                }
              >
                <div className="textten">Check</div>
                <img src={arrowwhite} className="arrrowTen" />
              </div>
              <img src={imgApi} className="logoTen" />
            </div>
          </div>

          {/*  */}
          <div
            className="innertencard"
            style={{
              background: "#44c2f408",
            }}
          >
            <div className="toptencard">
              <img className="topImage" src={retlogo} />
            </div>
            <h2>AI Enabled Retirement Planner</h2>
            <h5>
              An AI-Powered Retirement Planner Which Maps Your Financial Goals
              Over The Best Investments In The Crypto Space.{" "}
            </h5>

            <div className="imageSectionTen">
              <div
                className="cirlcebutton"
                style={{
                  background: "#44C2F4",
                }}
                onClick={() => window.open("https://retired.app/", "_blank")}
              >
                <div className="textten">Check</div>
                <img src={arrowwhite} className="arrrowTen" />
              </div>
              <img src={retdownlogo} className="logoTen" />
            </div>
          </div>

          {/*  */}
          <div
            className="innertencard"
            style={{
              background: "#e6bf3208",
            }}
          >
            <div className="toptencard">
              <img className="topImage" src={declogo} />
            </div>
            <h2>Asset Backed Token Exchange </h2>
            <h5>
              DCE will be Dubai's first regulated exchange which allows you to
              buy and hold crypto, forex, and commodity-backed tokens.
            </h5>

            <div className="imageSectionTen">
              <div
                className="cirlcebutton"
                onClick={() => window.open("https://dce.group/", "_blank")}
                style={{
                  background:
                    " linear-gradient(161.94deg, #A26B29 8.58%, #E6BF32 92.3%)",
                }}
              >
                <div className="textten">Check</div>
                <img src={arrowwhite} className="arrrowTen" />
              </div>

              <img src={decdownlogo} className="logoTen" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
