import React from "react";
import "./SectionTwo.scss";
export default function SectionTwo() {
  return (
    <div className="SectionTwo">
      <div className="container zxcz">
        <div
          className="left-side"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <p>Join Us At The Worlds First</p>
          <h4>AI Investment Summit</h4>
          <div className="secondmobilecard background-tint-molblie">
            <div className="name">MARCH 23/23</div>
            <div className="date">7 PM EST | STREAM</div>
            <div className="reg">Register</div>
          </div>
          <h1>
            For over two years, the <span onClick={()=>window.open("https://dgg.ai/","_blank")}>Digital Global Group </span>has been
            working on secret product which blends the explosion in
            cryptocurrencies with the impact of AI. This announcement will share
            the affiliate marketing world and set a new precedent in financial
            enhancement products.
          </h1>
          <label>Register</label>
        </div>
        <div className="RightSideAirwallate background-tint">
          <h2>APRIL 23/23</h2>
          <h3>7 PM EST | STREAM</h3>

          <div className="sectionbottombanner">
          Click Here To Save Your Spot
          </div>
        </div>
      </div>
    </div>
  );
}
