import React, { createContext, useState } from "react";
export const MainContext = createContext();
export const MainContextProvider = ({ children }) => {
  const [navHovercontext, setnavHovercontext] = useState("About");
  const [name, setname] = useState("");
  const [imageName, setimageName] = useState("");
  const [devOn, setDevOn] = useState(false);
  const value = {
    navHovercontext,
    setnavHovercontext,
    name,
    setname,
    imageName,
    setimageName,
  };
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
