import React from "react";
import "./SectionThree.scss";
import d1 from "../../static/images/d1.png";
import d2 from "../../static/images/d2.png";
import d3 from "../../static/images/d3.png";
export default function SectionThree() {
  return (
    <div className="Section-Three">
      <div className="container">
        <div
          className="top-sectionthree"
          data-aos="fade-top"
          data-aos-duration="1000"
        >
          <h1>Who Are We?</h1>
          <h2>Where AI Investing Meets Affiliate Marketing</h2>
          <div className="border"></div>
          <p>
            AIProWallet is a financial management platform that organizes all
            AI-enabled investment opportunities into a single point-of-access
            application for traders, investors and affiliates.
          </p>
        </div>
        <div
          className="bottom-sectionThree"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <div
            className="card-sectionthree background-tint"
            style={{ backgroundImage: `url(${d1})` }}
          >
            <h3>Our Journey</h3>
            <h5>2019 - 2023</h5>
          </div>
          <div
            className="card-sectionthree background-tint"
            style={{ backgroundImage: `url(${d2})` }}
          >
            <h3>The Announcement</h3>
            <h5>March 2023</h5>
          </div>
          <div
            className="card-sectionthree background-tint"
            style={{ backgroundImage: `url(${d3})` }}
          >
            <h3>The Future</h3>
            <h5>2023 - Beyond </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
