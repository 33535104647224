import React from "react";
import k1 from "../../static/images/you.svg";
import k2 from "../../static/images/k2.svg";
import k3 from "../../static/images/k3.svg";
import k4 from "../../static/images/k4.svg";
import k5 from "../../static/images/k5.svg";
import t1 from "../../static/images/t1.svg";
import t2 from "../../static/images/t2.svg";
import t3 from "../../static/images/t3.svg";
import t4 from "../../static/images/t4.svg";
import "./footer.scss";
import andriod from "../../static/images/linkios.svg";
import ios from "../../static/images/linkandriod.svg";
import landinglife from "../../static/images/dglogo.svg";
export default function index() {
  return (
    <>
      <div className="topFooter">
        <div className="container">
          <div className="topfooterSection">
            <div className="firsttopside first">
              <img className="logo" src={landinglife} />
              <div className="labelsecial-media">
                <div className="social-label">
                  <img
                    onClick={() =>
                      window.open(
                        "https://testflight.apple.com/join/ryjgm0ZP",
                        "_blank"
                      )
                    }
                    src={andriod}
                  />
                </div>
                <div className="social-label">
                  <img
                    onClick={() =>
                      window.open(
                        "https://drive.google.com/file/d/1tFW8wK2E4uktSyS-kMwCqTWqBRAKizf5/view?usp=share_link",
                        "_blank"
                      )
                    }
                    src={ios}
                  />
                </div>
              </div>
            </div>
            <div className="footertable">
              <h1>Popular</h1>
              <h2>Buy Bitcoin</h2>
              <h2>Sell Bitcoin </h2>
              <h2>Earn BTC</h2>
              <h2>Ai Index</h2>
            </div>

            <div className="footertable">
              <h1>Exchange Fees</h1>
              <h2>Deposit Fiat</h2>
              <h2>Withdraw Fiat</h2>
              <h2>Fiat To Crypto</h2>
              <h2>Crypto To Crypto</h2>
            </div>

            <div className="footertable">
              <h1>Support</h1>
              <h2>FAQ’s</h2>
              <h2>Training</h2>
              <h2>How It Works</h2>
              <h2>Support Center</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-airwallet">
        <div className="container">
          <div className="innerfooter">
            <div className="left-footer">
              <h4 style={{ width: "auto" }}>Follow Us On Social</h4>
              <div className="socialmidia">
                <img src={k1} />
                <img src={k2} />
                <img src={k3} />
                <img src={k4} />
                <img src={k5} />
                <img src={t1} />
                <img src={t2} />
                <img src={t3} />
                <img src={t4} />
              </div>
            </div>
            <div className="border-footer"></div>
            <div className="right-footer">
              <h4>Join Our Newsletter</h4>
              <div className="input-div">
                <input placeholder="Enter Email.." />
                <div className="join">Join</div>
              </div>
            </div>
          </div>
          <h5 className="h5">All Rights Reserved© AiProWallet.com</h5>
          <h5>Terms Of services And Privacy Policy © AiProWallet.com</h5>
        </div>
      </div>
    </>
  );
}
