import React from "react";
import "./SectionFour.scss";
import m1 from "../../static/images/v1.svg";
import m2 from "../../static/images/v2.svg";
import m3 from "../../static/images/v3.svg";
import m4 from "../../static/images/v4.svg";

import boxc from "../../static/images/boxc.svg";
export default function SectionFour() {
  return (
    <>
      <div className="sectionfour">
        <div className="container">
          <h1>What Ai Can Do For You?</h1>
          <p>As A Cryptocurrency Investor</p>

          <div
            className="box-four"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div className="fourCard">
              <img src={m1} />
              <h2>
                Crypto <br /> Acquisition
              </h2>
              <h5>
                Remove the complexity and decision-making involved in buying
                crypto by creating an Automated Purchase Plan (APP) with
                OTCBots. Start building your crypto portfolio at $5 a day.
              </h5>
            </div>
            <div className="fourCard">
              <img src={m2} />
              <h2>
                Active <br /> Trading{" "}
              </h2>
              <h5>
                Trading the crypto markets is a difficult and time consuming
                endeavour that is why allow you to access the best AI powered
                investment opportunities from companies all over the world via
                Ai Index.
              </h5>
            </div>
            <div className="fourCard">
              <img src={m3} />
              <h2>
                Earning <br /> Via Defi
              </h2>
              <h5>
                AI is revolutionizing the Defi world. We are at the frontier of
                this convergence, offering you a full suite of automated Defi
                tools you can leverage to grow your existing crypto holdings
                passively.
              </h5>
            </div>
            <div className="fourCard">
              <img src={m4} />
              <h2>
                Tax Loss <br />
                Harvesting
              </h2>
              <h5>
                It is a strategy where you sell your crypto assets with a
                reduced value at the end of a tax year to reduce your tax
                burden. Our Portfolio.Ai product line automatically adjusts your
                holdings to minimize tax liabilities.
              </h5>
            </div>
          </div>
        </div>
      </div>

      {/*
       */}
      <div className="sectionfour">
        <div className="container">
          <h1>What Ai Can Do For You?</h1>
          <p>As A Trader Or Investment Company</p>

          <div
            className="box-four"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div className="fourCard">
              <img src={m1} />
              <h2>
                List Your
                <br /> Offering
              </h2>
              <h5>
                Our core product, the AI Index, helps customers discover
                AI-powered investing opportunities. Plus, your product into the
                fastest growing community of AI Investing enthusiasts.
              </h5>
            </div>
            <div className="fourCard">
              <img src={m2} />
              <h2>
                Gain Instant
                <br /> Affiliates
              </h2>
              <h5>
                Our state-of-the-art Affiliate management system enables you to
                tap into an existing pool of affiliates, brokers, and
                influencers who can promote your product.
              </h5>
            </div>
            <div className="fourCard">
              <img src={m3} />
              <h2>
                Streamline Your <br />
                Onboarding
              </h2>
              <h5>
                Setting up and maintaining registration pages, CRM, and payment
                gateways are no longer necessary. Your customers can purchase
                your offering directly from their AiProWallet account.
              </h5>
            </div>
            <div className="fourCard">
              <img src={m4} />
              <h2>
                Launch & Grow <br />
                Your Fund
              </h2>
              <h5>
                Want an end-to-end fund solution? Deploy a passively or actively
                managed tokenized fund directly on AIProWallet and start making
                profits for your customers without the hassle.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="sectionfour">
        <div className="container">
          <h1>What Ai Can Do For You?</h1>
          <p className="p">
            As A Broker, Affiliate or Influencer. What Are You Currently
            Promoting?
          </p>
          <div className="broker">As A Broker, Affiliate or Influencer.</div>
          <div className="borderBroker"></div>
          <div className="broker">What Are You Promoting?</div>

          <div
            className="box-four"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div className="fourCardcutom">
              <h2 className="h2">
                Another Company’s
                <br />
                Product Or Service
              </h2>
              <h5 className="h5">
                Here are some ways AiProWallet can help you supercharge your
                existing business:
              </h5>
              <div className="deatil">
                <img src={boxc} />
                Your customers can acquire the crypto they need to join your
                company.
              </div>

              <div className="deatil">
                <img src={boxc} />
                List your company’s products in AI Index to gain exposure and
                automate your marketing.
              </div>

              <div className="deatil">
                <img src={boxc} />
                Add additional income streams like exchange fees, Defi and
                commissions from other products.
              </div>
            </div>
            <div className="fourCardcutom">
              <h2 className="h2">
                Looking For The Right
                <br /> Thing To Promote
              </h2>
              <h5 className="h5">
                Here are some reasons why AiProWallet will be the last platform
                you ever have to promote:
              </h5>
              <div className="deatil">
                <img src={boxc} />
                Earn commissions when your customers buy offerings from any AI
                Index listed companies.
              </div>
              <div className="deatil">
                <img src={boxc} />
                Earn commissions whenever your customer buys or sells
                cryptocurrencies.
              </div>
              <div className="deatil">
                <img src={boxc} />
                Create truly passive income through DefiShare, which pays you a
                reward every time your customer earns a reward.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
