import React, { useState, useEffect, useContext } from "react";
import "./mobilenav.scss";
import logo from "../../static/images/dglogo.svg";
import down from "../../static/images/down.svg";
import up from "../../static/images/up.svg";
import { MainContext } from "../../context/MainContext";
import c1 from "../../static/images/c1.svg";
import c2 from "../../static/images/c2.svg";
import c3 from "../../static/images/c3.svg";
import c4 from "../../static/images/c4.svg";

import a1 from "../../static/images/a1.svg";
import a2 from "../../static/images/a2.svg";
import a3 from "../../static/images/a3.svg";
import a4 from "../../static/images/a4.svg";
import { useHistory } from "react-router-dom";
import o1 from "../../static/images/o1.svg";
import o2 from "../../static/images/o2.svg";
import o3 from "../../static/images/o3.svg";
import o4 from "../../static/images/o4.svg";
import p1 from "../../static/images/p1.svg";
import p2 from "../../static/images/p2.svg";
import p3 from "../../static/images/p3.svg";
import p4 from "../../static/images/p4.svg";
import p5 from "../../static/images/p5.svg";
import p6 from "../../static/images/p6.svg";
import p7 from "../../static/images/p7.svg";
import p8 from "../../static/images/p8.svg";
import DropDownInfo from '../DropDown/dropdown'
export default function index() {
  const [toggle, settoggle] = useState(false);
  const [product, setproduct] = useState("");
  const [toggleproduct, settoggleproduct] = useState(false);
  const [Communities, setCommunities] = useState(false);

  const [Company, setCompany] = useState(false);
  const [Affiliates, setAffiliates] = useState(false);

  const [openactions, setOpenactions] = useState(false);
  const [userwidth, setuserwidth] = useState(false);
  let navigate = useHistory();
  const { navHovercontext, setnavHovercontext, setname, setimageName } =
    useContext(MainContext);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 60) {
      setOpenactions(true);
    } else {
      setOpenactions(false);
    }
  };
  return (
    <>
   {userwidth&& <DropDownInfo/>}
      <div
        className="mobilenav"
        style={
          openactions
            ? { boxShadow: "0 5px 7px -10px #000000" }
            : { opacity: "1" }
        }
      >
        <div className="left-side">
          <img className="logo" src={logo} />
        </div>
        <div className="right-side-nav">
          <label
            onClick={() =>
              window.open("https://my.aiprowallet.com/register", "_blank")
            }
          >
            Get Started
          </label>
          {toggle ? (
            <div class="cross" onClick={() => settoggle(!toggle)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : (
            <div class="menu-icon" onClick={() => settoggle(!toggle)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>
      </div>
      {toggle && (
        <div className="drawer-nav">
         
          <div
            className="menu-drawer"
            onClick={() => {
              setuserwidth(!userwidth);
              settoggle(false);
            }}
          >
            <h3>BY AIR</h3>
          </div>
          <div
            className="menu-drawer"
            onClick={() => {
              settoggle(false);
              navigate.push("/about");
            }}
          >
            <h3>About</h3>
          </div>
          <div
            className="menu-drawer"
            onClick={() => {
              settoggleproduct(!toggleproduct);
              setproduct(toggleproduct ? "" : "product");
            }}
          >
            <h3>Products</h3>
            {toggleproduct ? (
              <img
                className="up"
                src={up}
                onClick={() => {
                  settoggleproduct(!toggleproduct);
                  setproduct("");
                }}
              />
            ) : (
              <img
                className="down"
                src={down}
                onClick={() => {
                  settoggleproduct(!toggleproduct);
                  setproduct("product");
                }}
              />
            )}
          </div>
          {product === "product" && (
            <div className="subdrawer">
              <h1>Data Products</h1>
              <div
                className="submenu-drawer"
                onClick={() => {
                  setimageName(p1);
                  setname("AI Index");
                  navigate.push("/products/aiindex");
                  settoggle(false);
                }}
              >
                <img className="lo" src={p1} />
                <h5>AI Index</h5>
              </div>
              <div
                className="submenu-drawer"
                onClick={() => {
                  setimageName(p2);
                  setname("DGAcademy");
                  navigate.push("/products/DGAcademy");
                  settoggle(false);
                }}
              >
                <img className="lo" src={p2} />
                <h5>DGAcademy</h5>
              </div>
              <h1>Automated Products</h1>
              <div
                className="submenu-drawer"
                onClick={() => {
                  setimageName(p3);
                  setname("Fiat To Crypto");
                  navigate.push("/products/FiatToCrypto");
                  settoggle(false);
                }}
              >
                <img className="lo" src={p3} />
                <h5>Fiat To Crypto</h5>
              </div>
              <div
                className="submenu-drawer"
                onClick={() => {
                  setimageName(p4);
                  setname("Crypto To Crypto");
                  navigate.push("/products/CryptoToCrypto");
                  settoggle(false);
                }}
              >
                <img className="lo" src={p4} />
                <h5>Crypto To Crypto</h5>
              </div>
              <h1>Automated Products</h1>
              <div
                className="submenu-drawer"
                onClick={() => {
                  setimageName(p5);
                  setname("StakingVaults");
                  navigate.push("/products/StakingVaults");
                  settoggle(false);
                }}
              >
                <img className="lo" src={p5} />
                <h5>StakingVaults</h5>
              </div>
              <div
                className="submenu-drawer"
                onClick={() => {
                  setimageName(p6);
                  setname("OTCBots");
                  navigate.push("/products/OTCBots");
                  settoggle(false);
                }}
              >
                <img className="lo" src={p6} />
                <h5>OTCBots</h5>
              </div>

              <div
                className="submenu-drawer"
                onClick={() => {
                  setimageName(p7);
                  setname("DGFX");
                  navigate.push("/products/DGFX");
                  settoggle(false);
                }}
              >
                <img className="lo" src={p7} />
                <h5>DGFX</h5>
              </div>

              <div
                className="submenu-drawer"
                onClick={() => {
                  setimageName(p8);
                  setname("DGPROF");
                  navigate.push("/products/DGPROF");
                  settoggle(false);
                }}
              >
                <img className="lo" src={p8} />
                <h5>DGPROF</h5>
              </div>
            </div>
          )}

          <div
            className="menu-drawer"
            onClick={() => {
              setAffiliates(!Affiliates);
              setproduct(Affiliates ? "" : "Affiliates");
            }}
          >
            <h3>Affiliates</h3>
            {Affiliates ? (
              <img
                className="up"
                src={up}
                onClick={() => {
                  setAffiliates(!Affiliates);
                  setproduct("");
                }}
              />
            ) : (
              <img
                className="down"
                src={down}
                onClick={() => {
                  setAffiliates(!Affiliates);
                  setproduct("Affiliates");
                }}
              />
            )}
          </div>
          {product === "Affiliates" && (
            <div className="subdrawer">
              <h1>Tools</h1>
              <div className="submenu-drawer">
                <img className="lo" src={a1} />
                <h5>Affiliate App</h5>
              </div>
              <div className="submenu-drawer">
                <img className="lo" src={a2} />
                <h5>NetworkChain</h5>
              </div>
              <div className="submenu-drawer">
                <img className="lo" src={a3} />
                <h5>AffiliateBank</h5>
              </div>
              <div className="submenu-drawer">
                <img className="lo" src={a4} />
                <h5>InstantFunnels</h5>
              </div>
              <h1>Opportunities</h1>
              <div className="submenu-drawer">
                <img className="lo" src={o1} />
                <h5>AI Index Referrals</h5>
              </div>
              <div className="submenu-drawer">
                <img className="lo" src={o2} />
                <h5>Management Fee Override</h5>
              </div>

              <div className="submenu-drawer">
                <img className="lo" src={o3} />
                <h5>Exchange Fees</h5>
              </div>

              <div className="submenu-drawer">
                <img className="lo" src={o4} />
                <h5>Staking Rewards Pool</h5>
              </div>
            </div>
          )}

          <div
            className="menu-drawer"
            onClick={() => {
              setCommunities(!Communities);
              setproduct(Communities ? "" : "Communities");
            }}
          >
            <h3>Communities</h3>
            {Communities ? (
              <img
                className="up"
                src={up}
                onClick={() => {
                  setCommunities(!Communities);
                  setproduct("");
                }}
              />
            ) : (
              <img
                className="down"
                src={down}
                onClick={() => {
                  setCommunities(!Communities);
                  setproduct("Communities");
                }}
              />
            )}
          </div>

          {product === "Communities" && (
            <div className="subdrawer">
              <h1>Data Products</h1>
              <div className="submenu-drawer">
                <img className="lo" src={c1} />
                <h5>Branding Materials</h5>
              </div>
              <div className="submenu-drawer">
                <img className="lo" src={c2} />
                <h5>Weekly Webinars</h5>
              </div>

              <div className="submenu-drawer">
                <img className="lo" src={c3} />
                <h5>Rankings & Rewards</h5>
              </div>
              <div className="submenu-drawer">
                <img className="lo" src={c4} />
                <h5>Training Center</h5>
              </div>
            </div>
          )}

          <div
            className="menu-drawer"
            onClick={() => {
              setCompany(!Company);
              setproduct(Company ? "" : "Company");
            }}
          >
            <h3>Company</h3>
            {Company ? (
              <img
                className="up"
                src={up}
                onClick={() => {
                  setCompany(!Company);
                  setproduct("");
                }}
              />
            ) : (
              <img
                className="down"
                src={down}
                onClick={() => {
                  setCompany(!Company);
                  setproduct("Company");
                }}
              />
            )}
          </div>

          {product === "Company" && (
            <div className="subdrawer">
              <h1>About Us</h1>
              <div className="submenu-drawer">
                <h5>Mission</h5>
              </div>
              <div className="submenu-drawer">
                <h5>Press</h5>
              </div>
              <div className="submenu-drawer">
                <h5>Contact</h5>
              </div>
              <div className="submenu-drawer">
                <h5>Policies</h5>
              </div>
              <h1>Team</h1>
              <div className="submenu-drawer">
                <h5>Founders</h5>
              </div>
              <div className="submenu-drawer">
                <h5>Super Affiliates</h5>
              </div>

              <div className="submenu-drawer">
                <h5>Influencers</h5>
              </div>

              <div className="submenu-drawer">
                <h5>Partners</h5>
              </div>
            </div>
          )}
          {/* <div className="labelsection">
            <label className="login-nav">Login</label>
            <label className="Getstarted">Get Started</label>
          </div> */}
        </div>
      )}
    </>
  );
}
