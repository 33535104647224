import React from "react";
import Navbar from "../../pages/Navbar";
import landinglife from "../../static/images/leftsidelanding.png";
import play from "../../static/images/play.svg";
import "./land.scss";
import mobile from "../../static/images/mobile.svg";

import left from "../../static/images/left.svg";
import right from "../../static/images/right.svg";
import house from "../../static/images/house.svg";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import Five from "./SectionFive";
import Eight from "./Eight";
import {
  AppearingContainer,
  AppearSequentialContainer,
} from "react-appear-on-scroll";
import "react-appear-on-scroll/dist/index.css";
import SectionFour from "./SectionFour";
import Six from "./Six";
import Nine from "./Nine";
import Ten from "./Ten";
import Menu from "../../components/navMenus/navmenu";
import MobileNav from "../../components/MobileNav";

import arrow from "../../static/images/arrow-affilate.svg";
import andriod from "../../static/images/andriod.svg";
import ios from "../../static/images/ios.svg";

import z1 from "../../static/images/z3.svg";
import z2 from "../../static/images/z2.svg";
import Footer from "../../components/Footer";
export default function landingPageSection() {
  return (
    <>
      <MobileNav />
      <Menu />

      <div className=" container landingPageDg">
        <div
          className="left-side-dg"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <img src={landinglife} />
        </div>
        <div
          className="right-side-dg"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h1>Unlock The Power Of AI Embedded Trading</h1>
          <p>
            We empower individuals to take control of their financial future. We
            provide the tools and support to build a reliable and stable storage
            of wealth.
          </p>
          <div className="mobile-image">
            <img
              src={z1}
              onClick={() =>
                window.open(
                  "https://drive.google.com/file/d/1tFW8wK2E4uktSyS-kMwCqTWqBRAKizf5/view?usp=share_link",
                  "_blank"
                )
              }
            />
            <img
              src={z2}
              onClick={() =>
                window.open(
                  "https://testflight.apple.com/join/ryjgm0ZP",
                  "_blank"
                )
              }
            />
          </div>
          <div className="labelsection-landing">
            <label
              className="login"
              onClick={() =>
                window.open("https://my.aiprowallet.com/", "_blank")
              }
            >
              Login
            </label>
            <label
              className="started"
              onClick={() =>
                window.open("https://my.aiprowallet.com/register", "_blank")
              }
            >
              Get Started
            </label>
          </div>
        </div>
        {/* <div className="footer-dg">
          <div className="inner-dg">
            <img src={left} />
            <h2>We Have Rebranded From DGP To AiProWallet. Read Why?</h2>
            <h4>DGP Has Become AiProWallet. Read Why?</h4>
            <img src={right} />
          </div>
        </div> */}
      </div>

      
      <div className="bottomBanner">
        <div className="container innerbanner">
          <div className="text-area">
            <h6>Download The Beta Version Today </h6>

            <div className="image-section">
              <img src={arrow} />
              <img src={arrow} />
              <img src={arrow} />
            </div>
          </div>
          {/* <div
            className="startfree"
          
          >
            Get Started For Free
          </div> */}
          <div className="store">
            <img
              className="paystore"
              onClick={() =>
                window.open(
                  "https://testflight.apple.com/join/ryjgm0ZP",
                  "_blank"
                )
              }
              src={andriod}
            ></img>
            <div className="line-affilate"></div>

            <img
              className="paystore"
              onClick={() =>
                window.open(
                  "https://drive.google.com/file/d/1tFW8wK2E4uktSyS-kMwCqTWqBRAKizf5/view?usp=share_link",
                  "_blank"
                )
              }
              src={ios}
            ></img>
          </div>
        </div>
      </div>











<Ten/>
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <Eight />
      <Footer />
      {/*
   
        
      
  
   */}
      {/* 
  
     
     */}
      {/* 
      <SectionFour /> */}
      {/* <Six />
   
      <Nine />
  
   
      <Five /> */}
      {/* <Ten /> */}

      {/* 
      
   

      <SectionFour />
      <Five />
    */}
    </>
  );
}
