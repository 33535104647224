import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ComingSoon from "./pages/ComingSoon";
import PropertyDetail from "./pages/PropertyDetail";
import Property from "./pages/PropertyPage";
import Landing from "./pages/LandingPage/landingPageSection";
import AOS from "aos";
import "aos/dist/aos.css";
import Product from "./pages/Product";
import Privacy from "./pages/Privacy";

function Routes() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Switch>
      {/* <Redirect to="/about"/> */}
      <Route exact path="/" render={() => <Redirect to="/about" />} />
      <Route path="/about" component={Landing} />
      <Route path="/products/:id" component={Product} />
      <Route exact path="/comingsoon" component={ComingSoon} />
      <Route exact path="/property" component={Property} />
      <Route exact path="/property/detail" component={PropertyDetail} />
      <Route exact path="/privacy" component={Privacy} />
    </Switch>
  );
}

export default Routes;
