import React from "react";
import w1 from "../../static/images/w1.svg";
import w2 from "../../static/images/w2.svg";

import f1 from "../../static/images/f1.svg";
import f2 from "../../static/images/f2.svg";
import f3 from "../../static/images/f3.png";
import e1 from "../../static/images/e1.png";
import g1 from "../../static/images/g1.png";
import "./Eight.scss";
export default function Eight() {
  return (
    <>
      <div className="eight">
        <div className="container innereight">
          <h1>
            Want To Be An Affiliate? Manage Your AI Business Anywhere. Anytime
          </h1>
          <p>With Our Affiliate Specific Mobile App</p>

          <div className="label-eight">
            <img
              src={w1}
              onClick={() =>
                window.open(
                  "https://testflight.apple.com/join/11e9Gj5O",
                  "_blank"
                )
              }
            />
            <img src={w2} />
          </div>

          <img className="eightimage" src={e1} />
        </div>
        {/* <img className="eightimagecutsom" src={g1} /> */}
      </div>

      <div className="eightmobile">
        <div className="container eightinner">
          <h1>
            Become An Affiliate
            <br />
            Anywhere. Anytime
          </h1>
          <p>With Our Affiliate Mobile App</p>
          <div className="eightlabel">
            <div className="label">
              <img
                src={f1}
                onClick={() =>
                  window.open(
                    "https://testflight.apple.com/join/11e9Gj5O",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="label">
              {" "}
              <img src={f2} />
            </div>
          </div>
          <img className="bimg" src={f3} />
        </div>
      </div>
    </>
  );
}
